<template>
    <div class="footer">
        <div class="terms">
            <span v-if="$store.getters.isEnglish">
                <a href="/terms/EULA" target="blank">EULA</a>
                <a href="/terms/PrivacyPolicy" target="blank">Privacy Policy</a>
            </span>
            <span v-else>
                <a href="/terms/EULA" target="blank">用户协议</a>
                <a href="/terms/PrivacyPolicy" target="blank">隐私政策</a>
            </span>
        </div>
        Copyright 2022 PAX Technology.All Rights Reserved v2.0.0
    </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.footer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #999999;
    .terms {
        padding-bottom: 10px;
        span {
            a {
                margin: 10px 40px;
                color: #333333;
                text-decoration: underline;
            }
        }
    }
}
</style>
