<template>
    <div class="startup-box">
        <div class="startup-info">
            <p class="logo">NEXTUDIO</p>
            <pre class="text log" v-if="formData.log">
                <p :class="['log-line', {err:item[0]==='STDERR'}]" v-for="(item,index) in formData.log" :key="item[0]+index">{{item[1]}}</p>
            </pre>
            <Spin dot :size="8" v-if="formData.status === 1" />
            <p class="text" v-if="formData.status === 1">{{ t('project.openWorkspace') }}</p>
            <Button class="btn-fill" type="primary" @click="openIDE" v-if="formData.status === 3"> {{ t('project.stillOpenIDE') }} </Button>
        </div>
        <Footer />
    </div>
</template>

<script>
import { defineComponent, inject, reactive } from 'vue'
import { useRouter } from 'vue-router'
import Footer from '@/views/layout/Footer.vue'
export default defineComponent({
    components: {
        Footer
    },
    setup() {
        const t = inject('$t')
        const http = inject('$http')
        const router = useRouter()
        const formData = reactive({
            status: 1,
            log: '',
            taskId: '',
            ideSn: '',
            ideToken: '',
            workspaceName: '',
            folder: '',
            async: '',
            projId: router.currentRoute.value.params.projId
        })

        http.project
            .getProjStart(formData.projId)
            .then(({ data }) => {
                formData.taskId = data.taskId
                formData.ideSn = data.ideSn
                formData.ideToken = data.ideToken
                formData.async = data.async
                formData.folder = data.folder
                formData.workspaceName = data.workspaceName
                if (formData.taskId) {
                    getTaskStatus()
                } else {
                    openIDE()
                }
            })
            .catch(({ response }) => {
                formData.status = 4
                formData.log = response.data.message
            })

        const getTaskStatus = () => {
            http.project
                .getTaskStatus(formData.projId, formData.taskId)
                .then(({ data }) => {
                    if (!data) return
                    console.log(data)
                    formData.log = data.log
                    formData.status = data.status
                    if (data.status === 1) {
                        setTimeout(() => getTaskStatus(), 1000)
                    } else if (data.status === 2) {
                        openIDE()
                    }
                })
                .catch(({ response }) => {
                    formData.status = 4
                    formData.log = response.data.message
                })
        }
        const openIDE = () => {
            // console.log(`/ide/${formData.ideSn}?tkn=${formData.ideToken}&sn=${formData.ideSn}${formData.folder ? '&folder=' + formData.folder : ''}`)
            window.open(`/ide/${formData.ideSn}/?tkn=${formData.ideToken}&sn=${formData.ideSn}${formData.folder ? '&folder=' + formData.folder : ''}`, '_self')
        }
        return {
            t,
            formData,
            openIDE
        }
    }
})
</script>

<style lang="less" scoped>
.startup-box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .startup-info {
        text-align: center;
        width: 700px;
        height: 300px;
        .logo {
            text-align: center;
            font-size: 60px;
            color: #2b32c9;
            font-weight: bold;
            padding-bottom: 40px;
        }
        .text {
            font-size: 16px;
            line-height: 32px;
            color: #9898a5;
            padding-bottom: 16px;
        }
        .log {
            margin-bottom: 20px;
            text-align: left;
            max-height: 300px;
            min-height: 100px;
            overflow-y: auto;
            line-height: 18px;
            color: #5d5c5c;
            white-space: pre-wrap;
            border: 1px solid #c6c6dc;
            border-radius: 6px;
            padding: 10px;
        }
    }
}
.log-line {
    line-height: 30px;
    &.err {
        color: red;
    }
}
</style>
